.feature-display #map-loader {
  position: relative;
  margin: 5rem auto;
}

.comunas {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.etapas {
  width: 100%;
  display: flex;
  gap: 0.4rem;
}

.etapas p {
  text-align: center;
  font-size: 0.874rem;
  padding: 0.4rem;
  color: white;
  flex: 1 1 1px;
}

.etapa-value {
  font-weight: 700;
}
.etapa-name {
  font-weight: 700;
}
