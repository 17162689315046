.info-overlay {
  inset: 0 0 0 0;

  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
}

.variable-desc {
  position: absolute;
  background: white;
  z-index: 999;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  box-sizing: border-box;
  flex-direction: column;
  gap: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.variable-desc h2 {
  font-size: 1.4rem;
  color: #356838;
}
.var-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 300px);
}

.var-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.var-card h3 {
  color: #77a77a;
}
