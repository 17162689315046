.location-tooltip {
  position: fixed;
  z-index: 99;
  top: 1rem;
  background-color: white;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #001800;
}

.location-tooltip p {
  margin: 0.5rem 1rem;
  font-size: 0.75rem;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .location-tooltip {
    top: 5rem;
  }
}
