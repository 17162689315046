.feature-display {
  background-color: white;
  position: absolute;
  top: 0rem;
  right: 0rem;
  bottom: 0;
  width: 450px;
  border-radius: 0.1em;
  z-index: 99;
  transition: 1s height;
  padding: 1rem 1.4rem;
  transition: 1s transform;
  overflow-y: scroll;
}

.feature-display.hidden {
  transform: translateX(350px);
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature-display h2 {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.07rem;
  color: #356838;
  margin: 0.6rem auto;
  line-height: 1.7rem;
  text-transform: uppercase;
}

.feature-display header p {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.75);
  margin: 0.3rem auto;
  font-weight: 700;
  letter-spacing: 0.06rem;
}
.feature-display h3 {
  font-size: 0.9rem;
  font-weight: 700;
  color: #072e48;
}

.features {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  align-content: center;
}

.feature {
  flex: 1 1 0px;
}
.feature-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.feature-label {
  font-weight: 700;
  font-size: 0.9rem;
  margin: 0.3rem;
}

.feature-value {
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
  color: #77a77a;
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.feature-display .content {
  margin-top: 1rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
}

.subfeatures {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features-list {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 3rem;
  right: 3rem;
  top: 3rem;
  bottom: 3rem;
  padding: 3rem;
  background-color: white;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  overflow-y: scroll;
  z-index: 1001;
}

.hidden {
  transform: translate(100%);
}

.close-container {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.close {
  position: fixed;
}

.close:hover {
  cursor: pointer;
}

.features-list p {
  font-size: 0.8rem;
}

.subfeature {
  background-color: rgb(242, 242, 243);
  width: 60%;
  padding: 1rem;
  margin: 1rem auto;
  text-align: center;
}
.subfeature h2 {
  text-align: center;
}

.subfeature .feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: none;
}

.values {
  margin-left: 2rem;
}
.subfeature .feature-value {
  font-size: 2rem;
}
.feature img {
  width: 4rem;
  opacity: 0.75;
  margin: 0 auto;
}

.features-list .etapa-badge {
  margin-left: 2rem;
}

.feature-display .close {
  display: none;
}

.features-list .close {
  display: block;
}

.chart-container {
  padding: 0.2rem;
  background-color: #ffffff;
  color: black;
  position: relative;
}

.chart-container h3 {
  font-weight: 900;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  margin: 1rem auto 0.5rem;
  color: #001800;
}

.chart-container select {
  padding: 0.3rem 1rem;
}
@media only screen and (max-width: 1000px) {
  .feature-display {
    width: 100vw;
    box-sizing: border-box;
    padding: 1rem clamp(1rem, 10%, 2rem);
    top: auto;
    height: 400px;
    overflow-y: scroll;
  }

  .feature-display .content {
    margin-bottom: 3rem;
  }

  .feature-display.hidden {
    transform: translateY(30%);
  }

  .chart-container {
    padding: 0.2rem;
    width: 80vw;
    background-color: #ffffff;
    color: black;
  }

  .feature-display .close {
    display: none;
  }

  .subfeature {
    width: auto;
  }

  .feature img {
    width: 4rem;
    opacity: 0.75;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 680px) {
  .feature-display {
    padding: 1.4rem 10%;
  }

  .features-list {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .subfeature {
    width: 90%;
  }

  .feature-value {
    font-size: 1.2rem;
  }

  .feature-label {
    margin: 0.4rem;
  }
}

@media only screen and (max-width: 480px) {
  .feature-display {
    padding: 1.6rem 1.4rem;
  }

  .feature-display .close {
    left: 1.4rem;
  }
  .feature img {
    width: 3rem;
    opacity: 0.75;
    margin: 0 auto;
  }
}

.recharts-tooltip-wrapper p {
  font-size: 14px;
}

.recharts-tooltip-item-list * {
  font-size: 14px;
}
