select {
  padding: 0.6rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  background-color: whitesmoke;
  margin-top: 0.4rem;
  box-sizing: border-box;
  appearance: none;
  width: 100%;
  color: #474747;
  cursor: pointer;
}

.select-group {
  position: relative;
}

.select-container::after {
  position: absolute;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  transform: rotate(45deg);
  content: "";
  width: 8px;
  height: 8px;
  top: 40%;
  right: 0.8rem;
  z-index: 999;
  transition: 0.2s ease transform, 0.2s ease top, 0.4s ease border-color;
  pointer-events: none;
  cursor: pointer;
}

select:focus {
  outline: 0;
}
