.slider input {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 0.2rem;
  background: #d3d3d3;
  display: block;
  opacity: 0.8;
  margin-top: 0.9rem;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  border-radius: 0.4rem;
}

.country-results {
  font-size: 0.8rem;
  color: #35963c;
  background-color: none;
  background: none;
  border: none;
  text-align: right;
  width: 100%;
  cursor: pointer;
  transition: 250ms color;
}

.country-results:hover {
  color: #41b949;
}

.country-results:active {
  color: black;
}
.slider input:hover {
  opacity: 1;
}
.slider {
  width: 100%;
  margin-top: 1rem;
}

.slider input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #77a77a; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 100%;
}

.slider input::-moz-range-thumb {
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #77a77a; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 100%;
}

.variable-info {
  display: flex;
  justify-content: space-between;
}

.info-icon:hover {
  cursor: pointer;
  opacity: 0.7;
}
