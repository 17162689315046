@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700; 900&display=swap");

* {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.4rem;
  letter-spacing: 0.02rem;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

h1 {
  font-size: 4.5rem;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  letter-spacing: 0.315rem;
}

.btn-primary {
  color: white;
  background-color: #319037;
  border: none;
  padding: 0.2rem 1rem;
  height: 45px;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.1rem;
  margin-top: 3rem;
  transition: 300ms background-color;
}

.btn-primary:hover {
  background-color: #319037be;
  cursor: pointer;
}

.btn-primary:active {
  background-color: white;
  cursor: black;
}

.btn-icon {
  margin-right: 0.4rem;
}

.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: #319037be;
  color: rgb(224, 224, 224);
  font-size: 0.875rem;
  cursor: auto;
}

.rounded {
  border-radius: 0.5rem;
}

.shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.zoom {
  transition: 0.4s transform ease;
  -webkit-transition: 0.4s transform ease;
}

.zoom:hover {
  transform: scale(1.2);
}
