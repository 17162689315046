.recharts-cartesian-axis-tick-value tspan {
  font-size: 0.75rem;
  color: white;
}

.recharts-legend-item-text {
  color: #666 !important;
  font-size: 0.7rem;
  margin-right: 0.5rem;
}

.recharts-legend-wrapper {
  transform: translateY(0px);
}
.xAxis tspan {
  font-size: 0.7rem;
  /*fill: rgb(102, 102, 102);*/
}

.swap-button {
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 999;
  border-radius: 50%;
  transform: translateY(-30px);
  position: absolute;
  right: 1.2rem;
  top: 10rem;
  transition: 450ms background-color;
  font-size: 12px;
}

.chart-controls {
  display: flex;
  gap: 0.5rem;
}

.chart-controls > * {
  flex: 1 1 50%;
}

.categorical-chart-container {
  height: 300px;
  position: relative;
}

.categorical-chart-container .lds-ripple {
  top: 50%;
  left: calc(50%);
  transform: translate(-50%, -50%) translate(15px, -30px);
}
