.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  z-index: 99;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-overlay.fade-out {
  animation: 1s fadeOut forwards;
  -webkit-animation: 1s fadeOut forwards;
}

.loading-overlay p {
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.2rem;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}
