@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700; 900&display=swap);
* {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  line-height: 1.4rem;
  letter-spacing: 0.02rem;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

h1 {
  font-size: 4.5rem;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  letter-spacing: 0.315rem;
}

.btn-primary {
  color: white;
  background-color: #319037;
  border: none;
  padding: 0.2rem 1rem;
  height: 45px;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: 0.1rem;
  margin-top: 3rem;
  transition: 300ms background-color;
}

.btn-primary:hover {
  background-color: #319037be;
  cursor: pointer;
}

.btn-primary:active {
  background-color: white;
  cursor: black;
}

.btn-icon {
  margin-right: 0.4rem;
}

.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: #319037be;
  color: rgb(224, 224, 224);
  font-size: 0.875rem;
  cursor: auto;
}

.rounded {
  border-radius: 0.5rem;
}

.shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.zoom {
  transition: 0.4s -webkit-transform ease;
  transition: 0.4s transform ease;
  transition: 0.4s transform ease, 0.4s -webkit-transform ease;
  -webkit-transition: 0.4s transform ease;
}

.zoom:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #319037;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
    0% {
        top: 18px;
        left: 18px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 36px;
        height: 36px;
        opacity: 0;
    }
}
@keyframes lds-ripple {
    0% {
        top: 18px;
        left: 18px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 36px;
        height: 36px;
        opacity: 0;
    }
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  padding: 1rem clamp(2rem, 5vw, 5rem);
  color: white;
  align-items: flex-start;
  z-index: 1;
  transition: 1s opacity, 1s visibility;
  text-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.home::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0);
}
.home.animate::before {
  -webkit-animation: blendOutHome ease-out 5s forwards;
          animation: blendOutHome ease-out 5s forwards;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.home h1 {
  opacity: 0;
  -webkit-animation: 1s fadeIn ease 0.4s forwards;
          animation: 1s fadeIn ease 0.4s forwards;
  -wenkit-animation: 1s fadeIn ease 0.4s forwards;
}

.home .team {
  font-size: 0.9rem;
  font-style: italic;
  letter-spacing: 0;
}

.home h2 {
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.home p {
  color: white;
  width: 55rem;
  font-size: 1rem;
  letter-spacing: 0.17rem;
}

.home p.first {
  opacity: 0;
  animation: 1s fadeIn ease 0.8s forwards;
  -webkit-animation: 1s fadeIn ease 0.8s forwards;
}

.home p.second {
  opacity: 0;
  -webkit-animation: 1s fadeIn ease 1.2s forwards;
          animation: 1s fadeIn ease 1.2s forwards;
  -wenkit-animation: 1s fadeIn ease 1.2s forwards;
}

.logos {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}
.logo-isci {
  height: 40px;
  margin-top: 3rem;
  opacity: 1;
  z-index: 9999;
  transition: 1s bottom, 1s left;
}
.logo-ind {
  height: 60px;
  margin-top: 3rem;
  opacity: 1;
  z-index: 9999;
  transition: 1s bottom, 1s left;
}

#map-loader {
  position: absolute;
  bottom: 18px;
  right: 18px;
}

@-webkit-keyframes blendOutHome {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 20%;
  }
}

@keyframes blendOutHome {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 20%;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@media only screen and (max-width: 860px) {
  * {
    font-size: 14px;
  }
  .home h1 {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .home h2 {
    font-size: 0.8rem;
  }
  .home p {
    width: 100%;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
  }
  .logo-isci {
    height: 30px;
    margin-top: 3rem;
    opacity: 1;
    z-index: 9999;
    transition: 1s bottom, 1s left;
  }
  .logo-ind {
    height: 40px;
    margin-top: 3rem;
    opacity: 1;
    z-index: 9999;
    transition: 1s bottom, 1s left;
  }
}

.map-container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

main {
  position: relative;
  overflow: hidden;
}

.chile-button-container {
  position: fixed;
  left: 1rem;
  bottom: 3rem;
  z-index: 1;
}

.chile-button {
  width: 4rem;
  cursor: pointer;
  transition: 250ms -webkit-transform ease-in;
  transition: 250ms transform ease-in;
  transition: 250ms transform ease-in, 250ms -webkit-transform ease-in;
  -webkit-transition: 250ms transform ease-in;
}
.chile-button:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.tooltip {
  position: relative;
}
.tooltip:hover:before {
  content: "Ver resultados país";

  background-color: black;
  color: white;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

  left: 100%;
  margin-left: 0.8rem;

  border-radius: 0.4rem;
  padding: 0.4rem;
  width: 140px;
  text-align: center;
  z-index: 2;
}

.console {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: white;
  z-index: 1;
  display: flex;
  width: 300px;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: height 1s ease;
  color: #001800;
  transition: 1s;
  -webkit-transition: 1s;
  overflow: hidden;
}

.console.active {
  opacity: 1;
  visibility: visible;
}

.console label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #001800;
  letter-spacing: 0.06rem;
}

.row {
  height: 24px;
  width: 100%;
}

.color-legend {
  margin-top: 1rem;
}

.color-legend h3 {
  font-size: 1rem;
}
.colors {
  margin-bottom: 5px;
  margin-top: 0.5rem;
}

.legend-labels {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 0.875rem;
  font-weight: bold;
}
.labels {
  display: flex;
  justify-content: space-between;
  font-size: 0.6rem;
  position: relative;
  top: 10px;
}

.labels div {
  font-size: 0.8rem;
}

label {
  font-size: 0.875rem;
}

.layers-group {
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
  grid-row-gap: 0.4rem;
  row-gap: 0.4rem;
}

.layer-toggle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
}
.layer-toggle label {
  margin: 0;
}

.range-input {
  width: 100%;
}

.break {
  flex-basis: 100%;
  height: 0;
}
.filters ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

hr {
  background-color: rgba(66, 66, 66, 0.2);
  width: 100%;
  height: 1px;
  border-width: 0px;
}

.arrow {
  display: none;
}

.console header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  background-color: #001800;
  color: white;
}

.menu-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.console header h2 {
  font-size: 1rem;
  font-weight: 700;
}

.content {
  padding: 1rem;
}

.console-navigation {
  width: 100%;
}
.console-navigation li {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  color: rgb(187, 187, 187);
}

.console-navigation li svg {
  pointer-events: none;
}

.console-navigation li:hover {
  color: white;
}

.console-navigation li.active {
  color: white;
  border-bottom: 4px solid #49d152;
}

.console-navigation ul {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
}

.break {
  flex-basis: 100%;
  height: 0;
}

@media only screen and (max-width: 860px) {
  .console {
    height: auto;
    overflow: hidden;
    right: 1rem;
    width: auto;
  }

  .menu-header {
    justify-content: flex-start;
  }

  .menu-header .arrow {
    margin-left: auto;
  }
  .console .content {
    display: none;
  }

  .console .console-navigation {
    display: none;
  }

  .console.open .content {
    display: block;
  }

  .console.open .console-navigation {
    display: block;
  }

  .console h2 {
    margin: 5px;
    font-size: 1rem;
  }

  .console header .arrow {
    display: inline;
  }

  .fa-arrow-down {
    transition: 450ms -webkit-transform;
    transition: 450ms transform;
    transition: 450ms transform, 450ms -webkit-transform;
  }
  .fa-arrow-down.rotate {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.section-header svg {
  color: #001800;
}

.section-header h3 {
  margin: 0.875rem 0;
}

.filter-info {
  font-size: 0.75rem;
  line-height: 1.1rem;
  width: 100%;
  color: rgb(66, 66, 66);
  text-align: left;
}

select {
  padding: 0.6rem 0.8rem;
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  background-color: whitesmoke;
  margin-top: 0.4rem;
  box-sizing: border-box;
  -webkit-appearance: none;
          appearance: none;
  width: 100%;
  color: #474747;
  cursor: pointer;
}

.select-group {
  position: relative;
}

.select-container::after {
  position: absolute;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  content: "";
  width: 8px;
  height: 8px;
  top: 40%;
  right: 0.8rem;
  z-index: 999;
  transition: 0.2s ease transform, 0.2s ease top, 0.4s ease border-color;
  pointer-events: none;
  cursor: pointer;
}

select:focus {
  outline: 0;
}



.slider input {
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  height: 0.2rem;
  background: #d3d3d3;
  display: block;
  opacity: 0.8;
  margin-top: 0.9rem;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  border-radius: 0.4rem;
}

.country-results {
  font-size: 0.8rem;
  color: #35963c;
  background-color: none;
  background: none;
  border: none;
  text-align: right;
  width: 100%;
  cursor: pointer;
  transition: 250ms color;
}

.country-results:hover {
  color: #41b949;
}

.country-results:active {
  color: black;
}
.slider input:hover {
  opacity: 1;
}
.slider {
  width: 100%;
  margin-top: 1rem;
}

.slider input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #77a77a; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 100%;
}

.slider input::-moz-range-thumb {
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: #77a77a; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 100%;
}

.variable-info {
  display: flex;
  justify-content: space-between;
}

.info-icon:hover {
  cursor: pointer;
  opacity: 0.7;
}

.etapa-legend {
  z-index: 1;
  padding: 0.4rem 1rem;
  display: none;
  border-top: 2px solid whitesmoke;
}

.etapa-legend.show {
  display: block;
  animation: fadeIn 1s forwards;
  -webkit-animation: fadeIn 1s forwards;
}

.etapa-legend.hide {
  animation: fadeOut 1s forwards;
  -webkit-animation: fadeOut 1s forwards;
}

.etapa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.etapa div {
  width: 25px;
  height: 15px;
  border-radius: 0.3rem;
}

.etapa p {
  margin: 0;
  font-weight: 500;
  font-size: 0.9rem;
}

.etapa-1 {
  background-color: #f75c5c;
}
.etapa-2 {
  background-color: #ffbf00;
}
.etapa-3 {
  background-color: #fff200;
}
.etapa-4 {
  background-color: #338ad1;
}
.etapa-5 {
  background-color: #afd0ef;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.feature-display {
  background-color: white;
  position: absolute;
  top: 0rem;
  right: 0rem;
  bottom: 0;
  width: 450px;
  border-radius: 0.1em;
  z-index: 99;
  transition: 1s height;
  padding: 1rem 1.4rem;
  transition: 1s -webkit-transform;
  transition: 1s transform;
  transition: 1s transform, 1s -webkit-transform;
  overflow-y: scroll;
}

.feature-display.hidden {
  -webkit-transform: translateX(350px);
          transform: translateX(350px);
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature-display h2 {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.07rem;
  color: #356838;
  margin: 0.6rem auto;
  line-height: 1.7rem;
  text-transform: uppercase;
}

.feature-display header p {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.75);
  margin: 0.3rem auto;
  font-weight: 700;
  letter-spacing: 0.06rem;
}
.feature-display h3 {
  font-size: 0.9rem;
  font-weight: 700;
  color: #072e48;
}

.features {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  align-content: center;
}

.feature {
  flex: 1 1;
}
.feature-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.feature-label {
  font-weight: 700;
  font-size: 0.9rem;
  margin: 0.3rem;
}

.feature-value {
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom: 0rem;
  margin-top: 0.5rem;
  color: #77a77a;
}

.feature {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.feature-display .content {
  margin-top: 1rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
}

.subfeatures {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features-list {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 3rem;
  right: 3rem;
  top: 3rem;
  bottom: 3rem;
  padding: 3rem;
  background-color: white;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  overflow-y: scroll;
  z-index: 1001;
}

.hidden {
  -webkit-transform: translate(100%);
          transform: translate(100%);
}

.close-container {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.close {
  position: fixed;
}

.close:hover {
  cursor: pointer;
}

.features-list p {
  font-size: 0.8rem;
}

.subfeature {
  background-color: rgb(242, 242, 243);
  width: 60%;
  padding: 1rem;
  margin: 1rem auto;
  text-align: center;
}
.subfeature h2 {
  text-align: center;
}

.subfeature .feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: none;
}

.values {
  margin-left: 2rem;
}
.subfeature .feature-value {
  font-size: 2rem;
}
.feature img {
  width: 4rem;
  opacity: 0.75;
  margin: 0 auto;
}

.features-list .etapa-badge {
  margin-left: 2rem;
}

.feature-display .close {
  display: none;
}

.features-list .close {
  display: block;
}

.chart-container {
  padding: 0.2rem;
  background-color: #ffffff;
  color: black;
  position: relative;
}

.chart-container h3 {
  font-weight: 900;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  margin: 1rem auto 0.5rem;
  color: #001800;
}

.chart-container select {
  padding: 0.3rem 1rem;
}
@media only screen and (max-width: 1000px) {
  .feature-display {
    width: 100vw;
    box-sizing: border-box;
    padding: 1rem clamp(1rem, 10%, 2rem);
    top: auto;
    height: 400px;
    overflow-y: scroll;
  }

  .feature-display .content {
    margin-bottom: 3rem;
  }

  .feature-display.hidden {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
  }

  .chart-container {
    padding: 0.2rem;
    width: 80vw;
    background-color: #ffffff;
    color: black;
  }

  .feature-display .close {
    display: none;
  }

  .subfeature {
    width: auto;
  }

  .feature img {
    width: 4rem;
    opacity: 0.75;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 680px) {
  .feature-display {
    padding: 1.4rem 10%;
  }

  .features-list {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .subfeature {
    width: 90%;
  }

  .feature-value {
    font-size: 1.2rem;
  }

  .feature-label {
    margin: 0.4rem;
  }
}

@media only screen and (max-width: 480px) {
  .feature-display {
    padding: 1.6rem 1.4rem;
  }

  .feature-display .close {
    left: 1.4rem;
  }
  .feature img {
    width: 3rem;
    opacity: 0.75;
    margin: 0 auto;
  }
}

.recharts-tooltip-wrapper p {
  font-size: 14px;
}

.recharts-tooltip-item-list * {
  font-size: 14px;
}

.feature-display #map-loader {
  position: relative;
  margin: 5rem auto;
}

.comunas {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.etapas {
  width: 100%;
  display: flex;
  grid-gap: 0.4rem;
  gap: 0.4rem;
}

.etapas p {
  text-align: center;
  font-size: 0.874rem;
  padding: 0.4rem;
  color: white;
  flex: 1 1 1px;
}

.etapa-value {
  font-weight: 700;
}
.etapa-name {
  font-weight: 700;
}

.chart-container .lds-ripple {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.chart-container-dem {
  height: 270px;
}

.recharts-cartesian-axis-tick-value tspan {
  font-size: 0.75rem;
  color: white;
}

.recharts-legend-item-text {
  color: #666 !important;
  font-size: 0.7rem;
  margin-right: 0.5rem;
}

.recharts-legend-wrapper {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.xAxis tspan {
  font-size: 0.7rem;
  /*fill: rgb(102, 102, 102);*/
}

.swap-button {
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 999;
  border-radius: 50%;
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
  position: absolute;
  right: 1.2rem;
  top: 10rem;
  transition: 450ms background-color;
  font-size: 12px;
}

.chart-controls {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.chart-controls > * {
  flex: 1 1 50%;
}

.categorical-chart-container {
  height: 300px;
  position: relative;
}

.categorical-chart-container .lds-ripple {
  top: 50%;
  left: calc(50%);
  -webkit-transform: translate(-50%, -50%) translate(15px, -30px);
          transform: translate(-50%, -50%) translate(15px, -30px);
}

.yAxis tspan {
  font-size: 0.75rem;
  fill: rgb(102, 102, 102);
}

.bar-label {
  font-size: 0.7rem;
}

.location-tooltip {
  position: fixed;
  z-index: 99;
  top: 1rem;
  background-color: white;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  background-color: #001800;
}

.location-tooltip p {
  margin: 0.5rem 1rem;
  font-size: 0.75rem;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .location-tooltip {
    top: 5rem;
  }
}

.info-overlay {
  inset: 0 0 0 0;

  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
}

.variable-desc {
  position: absolute;
  background: white;
  z-index: 999;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  box-sizing: border-box;
  flex-direction: column;
  grid-gap: 3rem;
  gap: 3rem;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.variable-desc h2 {
  font-size: 1.4rem;
  color: #356838;
}
.var-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 300px);
}

.var-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.var-card h3 {
  color: #77a77a;
}

.App {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  z-index: 99;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-overlay.fade-out {
  animation: 1s fadeOut forwards;
  -webkit-animation: 1s fadeOut forwards;
}

.loading-overlay p {
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: 0.2rem;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

