.console {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: white;
  z-index: 1;
  display: flex;
  width: 300px;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
  color: #001800;
  transition: 1s;
  -webkit-transition: 1s;
  overflow: hidden;
}

.console.active {
  opacity: 1;
  visibility: visible;
}

.console label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #001800;
  letter-spacing: 0.06rem;
}

.row {
  height: 24px;
  width: 100%;
}

.color-legend {
  margin-top: 1rem;
}

.color-legend h3 {
  font-size: 1rem;
}
.colors {
  margin-bottom: 5px;
  margin-top: 0.5rem;
}

.legend-labels {
  display: flex;
  justify-content: space-between;
}

.label {
  font-size: 0.875rem;
  font-weight: bold;
}
.labels {
  display: flex;
  justify-content: space-between;
  font-size: 0.6rem;
  position: relative;
  top: 10px;
}

.labels div {
  font-size: 0.8rem;
}

label {
  font-size: 0.875rem;
}

.layers-group {
  display: flex;
  margin-top: 0.5rem;
  flex-direction: column;
  row-gap: 0.4rem;
}

.layer-toggle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
}
.layer-toggle label {
  margin: 0;
}

.range-input {
  width: 100%;
}

.break {
  flex-basis: 100%;
  height: 0;
}
.filters ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

hr {
  background-color: rgba(66, 66, 66, 0.2);
  width: 100%;
  height: 1px;
  border-width: 0px;
}

.arrow {
  display: none;
}

.console header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  background-color: #001800;
  color: white;
}

.menu-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.console header h2 {
  font-size: 1rem;
  font-weight: 700;
}

.content {
  padding: 1rem;
}

.console-navigation {
  width: 100%;
}
.console-navigation li {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  color: rgb(187, 187, 187);
}

.console-navigation li svg {
  pointer-events: none;
}

.console-navigation li:hover {
  color: white;
}

.console-navigation li.active {
  color: white;
  border-bottom: 4px solid #49d152;
}

.console-navigation ul {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-start;
}

.break {
  flex-basis: 100%;
  height: 0;
}

@media only screen and (max-width: 860px) {
  .console {
    height: auto;
    overflow: hidden;
    right: 1rem;
    width: auto;
  }

  .menu-header {
    justify-content: flex-start;
  }

  .menu-header .arrow {
    margin-left: auto;
  }
  .console .content {
    display: none;
  }

  .console .console-navigation {
    display: none;
  }

  .console.open .content {
    display: block;
  }

  .console.open .console-navigation {
    display: block;
  }

  .console h2 {
    margin: 5px;
    font-size: 1rem;
  }

  .console header .arrow {
    display: inline;
  }

  .fa-arrow-down {
    transition: 450ms transform;
  }
  .fa-arrow-down.rotate {
    transform: rotate(-180deg);
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.section-header svg {
  color: #001800;
}

.section-header h3 {
  margin: 0.875rem 0;
}

.filter-info {
  font-size: 0.75rem;
  line-height: 1.1rem;
  width: 100%;
  color: rgb(66, 66, 66);
  text-align: left;
}
