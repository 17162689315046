.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  );
  padding: 1rem clamp(2rem, 5vw, 5rem);
  color: white;
  align-items: flex-start;
  z-index: 1;
  transition: 1s opacity, 1s visibility;
  text-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.home::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0);
}
.home.animate::before {
  animation: blendOutHome ease-out 5s forwards;
}

.hidden {
  opacity: 0;
  visibility: hidden;
}

.home h1 {
  opacity: 0;
  animation: 1s fadeIn ease 0.4s forwards;
  -wenkit-animation: 1s fadeIn ease 0.4s forwards;
}

.home .team {
  font-size: 0.9rem;
  font-style: italic;
  letter-spacing: 0;
}

.home h2 {
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
}

.home p {
  color: white;
  width: 55rem;
  font-size: 1rem;
  letter-spacing: 0.17rem;
}

.home p.first {
  opacity: 0;
  animation: 1s fadeIn ease 0.8s forwards;
  -webkit-animation: 1s fadeIn ease 0.8s forwards;
}

.home p.second {
  opacity: 0;
  animation: 1s fadeIn ease 1.2s forwards;
  -wenkit-animation: 1s fadeIn ease 1.2s forwards;
}

.logos {
  display: flex;
  gap: 20px;
  align-items: center;
}
.logo-isci {
  height: 40px;
  margin-top: 3rem;
  opacity: 1;
  z-index: 9999;
  transition: 1s bottom, 1s left;
}
.logo-ind {
  height: 60px;
  margin-top: 3rem;
  opacity: 1;
  z-index: 9999;
  transition: 1s bottom, 1s left;
}

#map-loader {
  position: absolute;
  bottom: 18px;
  right: 18px;
}

@keyframes blendOutHome {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 20%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@media only screen and (max-width: 860px) {
  * {
    font-size: 14px;
  }
  .home h1 {
    font-size: 1.4rem;
    font-weight: 700;
  }

  .home h2 {
    font-size: 0.8rem;
  }
  .home p {
    width: 100%;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
  }
  .logo-isci {
    height: 30px;
    margin-top: 3rem;
    opacity: 1;
    z-index: 9999;
    transition: 1s bottom, 1s left;
  }
  .logo-ind {
    height: 40px;
    margin-top: 3rem;
    opacity: 1;
    z-index: 9999;
    transition: 1s bottom, 1s left;
  }
}
