.etapa-legend {
  z-index: 1;
  padding: 0.4rem 1rem;
  display: none;
  border-top: 2px solid whitesmoke;
}

.etapa-legend.show {
  display: block;
  animation: fadeIn 1s forwards;
  -webkit-animation: fadeIn 1s forwards;
}

.etapa-legend.hide {
  animation: fadeOut 1s forwards;
  -webkit-animation: fadeOut 1s forwards;
}

.etapa {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}
.etapa div {
  width: 25px;
  height: 15px;
  border-radius: 0.3rem;
}

.etapa p {
  margin: 0;
  font-weight: 500;
  font-size: 0.9rem;
}

.etapa-1 {
  background-color: #f75c5c;
}
.etapa-2 {
  background-color: #ffbf00;
}
.etapa-3 {
  background-color: #fff200;
}
.etapa-4 {
  background-color: #338ad1;
}
.etapa-5 {
  background-color: #afd0ef;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
