.map-container {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

main {
  position: relative;
  overflow: hidden;
}

.chile-button-container {
  position: fixed;
  left: 1rem;
  bottom: 3rem;
  z-index: 1;
}

.chile-button {
  width: 4rem;
  cursor: pointer;
  transition: 250ms transform ease-in;
  -webkit-transition: 250ms transform ease-in;
}
.chile-button:hover {
  transform: scale(1.1);
}

.tooltip {
  position: relative;
}
.tooltip:hover:before {
  content: "Ver resultados país";

  background-color: black;
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  left: 100%;
  margin-left: 0.8rem;

  border-radius: 0.4rem;
  padding: 0.4rem;
  width: 140px;
  text-align: center;
  z-index: 2;
}
